/**
 *
 * @file
 * Gallery
 *
 */
(function ($) {
  'use strict';

  Drupal.behaviors.skGallery = {
    attach: function(context, settings) {
      if (typeof settings['juicebox'] !== 'undefined') {

        // Path to banner files inside the theme.
        var path = settings.basePath + 'sites/all/themes/skopjeinfo_3/gallery-ads';

        // Create iframe for rectangle banner 1.
        var rectangleIframe  = createIframe(path + '/rectangle-1.php');

        // Create iframe for billboard banner.
        var billboardDesktopIframe = createIframe(path + '/billboard-desktop.php');
        var billboardMobileIframe = createIframe(path + '/billboard-mobile.php');


        // Placement zones
        var billboardZone = $(context).find('.js-gallery-billboard');
        var rectangleZone = $(context).find('.js-gallery-rectangle');

        // juicebox_instances is a global variable created by juicebox module.
        var jb = juicebox_instances[0];

        // jb.onInitComplete = function() {
        //   console.log(jb.getImageCount());
        // };

        /**
         * On Image Change
         */
        jb.onImageChange = function(e) {
          // Append Billboard banner depends on display size.
          if(window.matchMedia('(min-width: 768px)').matches) {
            billboardDesktopIframe.appendTo(billboardZone)
                .on('load', function () {
                  $(this).css({
                    'width': $(this).contents().width(),
                    'height': $(this).contents().find('body').height()
                  });
                });
          }
          else {
           billboardMobileIframe.appendTo(billboardZone)
                .on('load', function () {
                  $(this).css({
                    'width': $(this).contents().width(),
                    'height': $(this).contents().find('body').height()
                  });
                });
          }

          // Append Rectangle banner
          rectangleIframe.appendTo(rectangleZone)
              .on('load', function () {
                $(this).css({
                  'width': $(this).contents().find('body').width(),
                  'height': $(this).contents().find('body').height()
                });
              });

        };

      }
    }
  };

  function createIframe(src) {
    return $('<iframe>', {
      src: src,
      frameborder: 0,
      scrolling: 'no'
    });
  }

})(jQuery);




