/**
 *
 * @file
 * Navigation tabs
 *
 */
(function ($) {
  'use strict';

  Drupal.behaviors.natusanaLocalTasks = {
    attach: function(context, settings) {
      var tabs = $(context).find('.tabs');
      var tabsToggleButtomn = $(context).find('.js-tabs-toggler');

      if (tabs.length) {
        tabsToggleButtomn.once().on('click', function() {
          tabs.toggleClass('active');
        });
      }
    }
  };
})(jQuery);
