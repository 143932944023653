/**
 *
 * @file
 * JS Background Utilities
 *
 * Since views doesn't allow usage of style attribute for security reasons,
 * you can use TheMAG "data-background" attribute in combination with few
 * additional attributes for this purpose.
 *
 * Available attributes which you can use in combination with data-background:
 * data-src - Path to image
 * data-color - Color HEX or RBA/RGBA
 * data-width - Element width. Default is 100%.
 * data-height - Element height.
 *
 * Example usage:
 * <div data-background data-color="#CCC"> ... </div>
 * <div data-background="image" data-src="image.jpg" data-height="60vh"> ... </div>
 *
 */

(function ($) {
  'use strict';

  Drupal.behaviors.backgroundUtilities = {
    attach: function(context, settings) {

      var elements = $(context).find('[data-background]');
      if(elements.length > 0) {

        var data = '';

        elements.each(function () {
          var data = $(this).data();

          // Set element background color
          if (typeof data.color !== "undefined") {
            $(this).css('background-color', data.color);
          }

          // Set element width to 100% if is not specified
          if (typeof data.width !== "undefined") {
            $(this).css('width', data.width)
          }

          // Set element height if specified
          if (typeof data.height !== "undefined") {
            $(this).css('height', data.height);
          }

          if(data.background === 'image') {
            // Set element height if specified
            if (typeof data.src !== "undefined") {
              $(this).css({
                'background-image': 'url(' + data.src.trim() + ')',
                'background-size': 'cover',
                'background-position': 'center center'
              })
            } else {
              console.error('data-src (background image) is required!')
            }
          }
        });
      }
    }
  }
})(jQuery);