/**
 *
 * @file
 * Ads correction script.
 *
 */
(function ($) {
  'use strict';

  $(window).on('load', function () {
    var iframedBanner = $('[data-revive-loaded="1"] > iframe');
    if (iframedBanner.length > 0) {
      iframedBanner.each(function () {
        iframedBanner.parent().addClass('sk-banner');
      });
    }
  });

})(jQuery);
